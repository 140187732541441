@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply font-medium text-base text-gray-500 mb-10;
  }
  .icon {
    @apply w-[70px]
    h-[70px]
    flex
    items-center
    justify-center
    rounded-md
    opacity-100  
    text-[rgba(74,108,247,.1)]
    mb-10;
  }
}

@layer components {
  /* Header Styles */

  .mobile-menu-bar {
    @apply relative
    w-[30px]
    h-0.5
    my-1.5
    block 
bg-[rgba(0,0,0,1)];
  }

  .navbarTogglerActive > span:nth-child(1) {
    @apply top-[7px] rotate-45 transform;
  }
  .navbarTogglerActive > span:nth-child(2) {
    @apply opacity-0;
  }
  .navbarTogglerActive > span:nth-child(3) {
    @apply -top-[8px] rotate-[135deg] transform;
  }
}

/* -----------------------------------------
                Custom CSS 
------------------------------------------*/
.active {
  color: #4a6cf7;
}
.projects-bg-image {
  background-image: linear-gradient(
    180deg,
    #3e7dff 0%,
    rgba(62, 125, 255, 0) 100%
  );
}

.input-field {
  outline: none;
  width: 100%;
  border-radius: 0.125rem;
  border-bottom-width: 2px;
  border-color: transparent;
  background-color: rgba(248, 248, 249, 0.8);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: rgba(149, 156, 177);
}
